import { g as getElement } from "./index-bc9b1baf.js";
import { i as isSSR, j as SUBSCRIPTIONS_KEY } from "./element-d66cb8c2.js";
import { a as SubscriptionList } from "./subscription-list.class-20a90866.js";
var LONG_PRESS_ENABLE_SUBJECT_KEY = "long-press-enable-subject";
var IS_TOUCH = !isSSR() && ("ontouchstart" in window || window.navigator.maxTouchPoints > 0 || window.navigator["maxTouchPoints"] > 0);
var MS_POINTER_ENABLED = !isSSR() && window.navigator && "msPointerEnabled" in window.navigator;
var HAS_POINTER_EVENTS = !isSSR() && ("PointerEvent" in window || MS_POINTER_ENABLED);
var EVENT_MOUSEDOWN = HAS_POINTER_EVENTS ? "pointerdown" : IS_TOUCH ? "touchstart" : "mousedown";
var EVENT_MOUSEUP = HAS_POINTER_EVENTS ? "pointerup" : IS_TOUCH ? "touchend" : "mouseup";
var EVENT_MOUSEMOVE = HAS_POINTER_EVENTS ? "pointermove" : IS_TOUCH ? "touchmove" : "mousemove";
var DEFAULT_MAX_DIFF_X = 10;
var DEFAULT_MAX_DIFF_Y = 10;
var DEFAULT_DURATION = 800;
function LongPress(i) {
  return function (n, o) {
    var t = n.componentDidLoad,
      r = n.disconnectedCallback,
      E = n.connectedCallback;
    var e = [o, "timer"].join("-");
    var S = [o, "startX"].join("-");
    var s = [o, "startY"].join("-");
    var u = ["clearLongPressTimer", o].join("-");
    var _ = ["mouseDownHandler", o].join("-");
    var a = ["mouseMoveHandler", o].join("-");
    function v(i) {
      if (!isSSR()) {
        var n = getElement(i);
        window.removeEventListener(EVENT_MOUSEUP, i[u], true);
        window.removeEventListener(EVENT_MOUSEMOVE, i[a], true);
        window.removeEventListener("wheel", i[u], true);
        window.removeEventListener("scroll", i[u], true);
        n.removeEventListener(EVENT_MOUSEDOWN, i[_], true);
      }
    }
    function l(i) {
      v(i);
      if (!isSSR()) {
        var n = getElement(i);
        window.addEventListener(EVENT_MOUSEUP, i[u], true);
        window.addEventListener(EVENT_MOUSEMOVE, i[a], true);
        window.addEventListener("wheel", i[u], true);
        window.addEventListener("scroll", i[u], true);
        n.addEventListener(EVENT_MOUSEDOWN, i[_], true);
      }
    }
    function T(n) {
      var t = getElement(n);
      var r = n[o];
      !(t[SUBSCRIPTIONS_KEY] instanceof SubscriptionList) && (t[SUBSCRIPTIONS_KEY] = new SubscriptionList());
      var E = t[SUBSCRIPTIONS_KEY];
      n[e] = null;
      n[S] = 0;
      n[s] = 0;
      n[u] = function () {
        clearTimeout(n[e]);
        n[e] = null;
      };
      n[_] = function (o) {
        var t;
        n[S] = o.clientX;
        n[s] = o.clientY;
        (t = n[u]) === null || t === void 0 ? void 0 : t.call(n);
        n[e] = setTimeout(function () {
          return r.call(n, o);
        }, (i === null || i === void 0 ? void 0 : i.duration) || DEFAULT_DURATION);
      };
      n[a] = function (o) {
        var t;
        var r = Math.abs(n[S] - o.clientX);
        var E = Math.abs(n[s] - o.clientY);
        if (r >= DEFAULT_MAX_DIFF_X || E >= ((i === null || i === void 0 ? void 0 : i.maxDiffY) || DEFAULT_MAX_DIFF_Y)) {
          (t = n[u]) === null || t === void 0 ? void 0 : t.call(n);
        }
      };
      E[LONG_PRESS_ENABLE_SUBJECT_KEY] = i.enabled$(n).subscribe(function (i) {
        if (i) {
          l(n);
        } else {
          v(n);
        }
      });
    }
    n.componentDidLoad = function () {
      T(this);
      return t === null || t === void 0 ? void 0 : t.call(this);
    };
    n.connectedCallback = function () {
      T(this);
      return E === null || E === void 0 ? void 0 : E.call(this);
    };
    n.disconnectedCallback = function () {
      var i = getElement(this);
      var n = i === null || i === void 0 ? void 0 : i[SUBSCRIPTIONS_KEY];
      n && n instanceof SubscriptionList && n.unsubscribeSafe(LONG_PRESS_ENABLE_SUBJECT_KEY);
      v(this);
      return r === null || r === void 0 ? void 0 : r.call(this);
    };
  };
}
export { LongPress as L };